// Gradient Mixin

@mixin gradient-vertical($start-color: #0c243d, $end-color: #134067, $start-percent: 0%, $end-percent: 100%) {
  background-image: -webkit-linear-gradient(top, $start-color $start-percent, $end-color $end-percent);  // Safari 5.1-6, Chrome 10+
  background-image: -o-linear-gradient(top, $start-color $start-percent, $end-color $end-percent);  // Opera 12
  background-image: linear-gradient(to bottom, $start-color $start-percent, $end-color $end-percent); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=0); // IE9 and down
}

@mixin vertical-center {
  min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh;

  display: flex;
  align-items: center;
}

@mixin float-label-text-input {
  border: none;
  border-bottom: 1px solid #2e96eb;
  border-radius: 0;
  outline: none;
  border-radius: 0;
  box-shadow: none !important;
  margin: 0 0 25px;
  font-size: 20px;
  height: 40px;
  line-height: 40px
}

@mixin submit-input {
  margin: 10px 0 25px !important;
  font-size: 18px;
  border-radius: 0px;
  height: 40px;
  padding: 0;
  &:hover {
    color: #fff !important
  }
}