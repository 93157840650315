body {
  min-height: 100%;
  background: #fff
}

html {
  height: 100vh;
}

* {
  max-height: 100000px;
}

.vertical-center {
  @include vertical-center();
}

.vertical-center-xs {
  @media (max-width: $screen-xs) {
    @include vertical-center();
  }
}

.table-responsive {
  overflow-x: inherit !important;
}

.pull-right {
  width: 100%;
}

.VueTables__limit {
  width: auto !important;
}

.table-striped > tbody > tr {
  background: #133f66 !important;
}

.table-striped > tbody > tr:nth-of-type(2n+1) {
  color: #fff;
  background: #0c243d !important;
}

.table-striped > tbody > tr:hover {
  color: #fff !important;
  background: #00c7fd !important;
}

.table-striped > tbody > tr.VueTables__child-row:hover {
  background: none !important;
}

.VueTables__table {
  thead {
    tr {
      th {
        @include gradient-vertical;
        color: #fff;
      }
    }
  }
}

.VueTables__child-row .VueTables {
  margin: 0 0 0 1px !important;
  width: calc(100% - 1px) !important
}

.VueTables tr td .glyphicon-download-alt {
  text-align: center;
  cursor: pointer
}

.table-bordered > thead > tr > th, .table-bordered > thead > tr > td, .table-bordered > tbody > tr > th, .table-bordered > tbody > tr > td, .table-bordered > tfoot > tr > th, .table-bordered > tfoot > tr > td {
  border-color: #159bd5 !important;
}

.table-bordered {
  border-color: #159bd5 !important;
}

.pull-right {
  .btn {
    float: left;
    margin-right: 10px;
  }
}

.table-responsive {
  .VueTables {
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin-top: -36px;
    width: 100%;
  }
}

.btn-default {
  position: relative;
  z-index: 999;
  border-radius: 0 !important;
  background: #133f66 !important;
  color: #fff !important;
  font-weight: 600 !important;
  border-color: #00c7fd !important;
  &:hover {
    background: #00c7fd !important;
    color: #fff !important;
  }
}

.pagination > .disabled > span, .pagination > .disabled > span:hover, .pagination > .disabled > span:focus, .pagination > .disabled > a, .pagination > .disabled > a:hover, .pagination > .disabled > a:focus, .pagination > li > a, .pagination > li > span {
  color: #fff !important;
  background-color: #0c243d !important;
  border-color: #00c7fd !important;
}

.pagination {
  li {
    a {
      &:hover {
        background-color: #00c7fd !important;
      }
    }
  }
}

.panel .VueTables__table tr td a.glyphicon:hover {
  color: #fff !important;
}

.modal-container {
  background-color: #000 !important;
  border-color: #00c7fd !important;
  padding: 20px !important;
  select {
    background: #fff;
    border-color: #00c7fd !important;
    color: #000 !important;
    display: block;
    height: 31px;
    width: 100%
  }
  input[type="text"] {
    color: #000;
    width: 100%
  }
}

.modal-content {
  @include gradient-vertical;
}

.modal-content .close {
  color: #fff
}

.panel-body select {
  background: none;
  border-color: #2e96eb !important;
  color: #2e96eb !important;
}

button[type="submit"] {
  background: none;
  border-color: #2e96eb !important;
  color: #2e96eb !important;
  width: 100%;
  display: block;
  margin: 0 auto;
}

.btn-success {
  color: $btn-success-color;
}

.btn-approve {
  color: $btn-approve-color;
}

.standard-text-input {
  @include float-label-text-input();
}

.standard-submit-button {
  @include submit-input();
}

.header {
  &__philips-logo {
    // height: 41px;
    width: calc(100% - 20px);
    margin-top: 75px;
  }
}

.login-form {
  margin-top: 45px;

  &__forgot-password-link {
    font-size: 20px
  }
}

.send-reset-form {
  margin-top: 45px;

  &__title {
    display: block;
    color: #2e96eb;
    font-size: 28px;
    margin: 0px;
  }
  &__email-input {
    margin-top: 45px;
  }
  &__back-link {
    font-size: 20px
  }
}

@media screen and (max-width: 1200px) {
  .btn-default {
    font-size: 12px !important;
    margin-bottom: 10px !important;
  }
}

@media screen and (max-width: 990px) {
  .table-responsive .VueTables {
    margin-top: 10px;
  }
  .table-responsive {
    border-color: #00c7fd !important;
  }
  .VueTables__limit {
    float: none !important;
    padding: 0 15px;
  }
  .nav .dropdown.open .dropdown-menu a {
    // color: #fff !important;
  }
}
.btn-space {
  margin-right: 5px;
}