// Body
$body-bg: #134067;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;


// Brands
$brand-primary: #2579a9;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger: #bf5329;
$color-white:#fff;

// Typography
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$font-family-sans-serif: "Raleway", sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: #636b6f;

// Buttons
$btn-primary-color: #2e96eb;
$btn-primary-bg: $brand-primary;
$btn-primary-border: #2e96eb;

$btn-success-color:#fff !important ;
$btn-success-bg:$brand-success;
$btn-success-border: darken($btn-success-bg, 5%) !default;
$btn-approve-color:#fff !important;
$btn-approve-bg:$brand-warning;
$btn-approve-border: darken($btn-approve-bg, 5%) !default;

// Inputs
$input-border: #2e96eb;
$input-color-placeholder: #2e96eb;
$input-bg: #fff;
$input-color: #2e96eb;

// Panels
$panel-default-heading-bg: #fff;

